export const clearKeys = (keys) =>
    Object.keys(keys).reduce((acc, cur) => ({ ...acc, [cur]: Math.random() }), {});

// Don't use this utility if you want 0 values returned
export const removeNonValuesFromObject = (obj) =>
    Object.entries(obj)
        .filter((el) => el[1])
        .reduce(
            (acc, curr) => ({
                ...acc,
                [curr[0]]: curr[1]
            }),
            {}
        );

export const onlyEntries = (keysToKeep, object) => {
    const trimmedObject = {};
    keysToKeep.forEach((el) => {
        if (Object.hasOwn(object, el)) trimmedObject[el] = object[el];
    });
    return trimmedObject;
};

export const trimEntries = (keysToTrim, object) => {
    const trimmedObject = {};
    Object.keys(object).forEach((el) => {
        if (!keysToTrim.includes(el)) trimmedObject[el] = object[el];
    });
    return trimmedObject;
};

export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    if (obj1 instanceof Date && obj2 instanceof Date) {
        return obj1.getTime() === obj2.getTime();
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key)) return false;
        if (!deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
};

// checks if a variable is an object but doesn't treat arrays like object because that's weird, take notes JavaScript
export const isObject = (value) => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
};

// will set to null each value
export const clearValues = (obj) => {
    const retObj = { ...obj };
    Object.keys(retObj).forEach((key) => {
        retObj[key] = null;
    });
    return retObj;
};

// will set to null each value, or [] if array, excluding objects
export const deepClearValues = (obj) => {
    const retObj = { ...obj };
    Object.keys(retObj).forEach((key) => {
        if (isObject(retObj[key])) retObj[key] = deepClearValues(retObj[key]);
        else if (Array.isArray(retObj[key])) retObj[key] = [];
        else retObj[key] = null;
    });
    return retObj;
};

export const getEmptyPaginationState = () => {
    return {
        pageable: {
            sort: {
                empty: false,
                unsorted: false,
                sorted: true
            },
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true,
            unpaged: false
        },
        last: true,
        totalPages: 0,
        totalElements: 0,
        size: 10,
        number: 0,
        sort: {
            empty: false,
            unsorted: false,
            sorted: true
        },
        first: true,
        numberOfElements: 0,
        empty: true
    };
};

export const formatStateToPaginate = (state, payload) => {
    const tempState =
        payload.number === 0 ? Array.from({ length: payload.totalPages }, () => []) : state;
    tempState[payload.number] = [...payload.content];
    return tempState;
};
