import React from 'react';
import PropTypes from 'prop-types';

import SingleSelect from '../../formElements/SingleSelect';

import form from '../../../commonStyles/formStyles.module.css';

export const initialDefaultStatus = {
    contract: {}
};

const ModalContentDefault = ({ contracts = [], errors, selects, onChange }) => {
    return (
        <div className={form.formSection}>
            <SingleSelect
                id="contractId"
                label="Contract"
                placeholder="Select Contract..."
                mandatory={true}
                menuItems={contracts}
                selectedId={selects.contract?.id || ''}
                selected={
                    (selects.contract?.id &&
                        contracts.find((el) => el.id === selects.contract.id)) ||
                    {}
                }
                error={errors.contract}
                onChange={(id) =>
                    onChange(
                        'contract',
                        contracts.find((el) => el.id === id)
                    )
                }
            />
        </div>
    );
};

export const ModalActionDefault = () => {
    return null;
};

export default ModalContentDefault;

ModalContentDefault.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.object,
    selects: PropTypes.object,
    onChange: PropTypes.func
};
