import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadServiceDetailsForContractId } from '../../../store/directusService';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import SingleSelect from '../../formElements/SingleSelect';

import form from '../../../commonStyles/formStyles.module.css';

export const initialFinancialRequestStatus = {
    contract: {},
    serviceId: '',
    date: String(new Date().toISOString().split('T')[0])
};

const ModalContentFinancialRequest = ({ contracts = [], errors, selects, onChange }) => {
    // HOOKS
    const dispatch = useDispatch();

    // STORE STATE
    const serviceDetailsForContractId = useSelector(
        (state) => state.entities.directusService.serviceDetailsForContractId
    );

    // USE EFFECTS
    useEffect(() => {
        onChange('serviceId', '');
    }, [serviceDetailsForContractId]);

    useEffect(() => {
        selects.contract?.id && dispatch(loadServiceDetailsForContractId(selects.contract.id));
    }, [selects.contract]);

    return (
        <div className={form.formSection}>
            <div className={form.formColumn}>
                <SingleSelect
                    id="contractId"
                    label="Contract"
                    placeholder="Select Contract..."
                    mandatory={true}
                    menuItems={contracts}
                    selectedId={selects.contract?.id || ''}
                    selected={
                        (selects.contract?.id &&
                            contracts.find((el) => el.id === selects.contract.id)) ||
                        {}
                    }
                    error={errors.contract}
                    onChange={(id) =>
                        onChange(
                            'contract',
                            contracts.find((el) => el.id === id)
                        )
                    }
                />
                <div className={form.alignDown}>
                    <DateSelect
                        id="datePicker"
                        label={'Date Selector'}
                        value={selects.date}
                        isDefault={true}
                        mandatory={true}
                        max={new Date().toISOString().split('T')[0]}
                        onDateChange={(date) => onChange('date', date)}
                    />
                </div>
            </div>
            <div className={form.formColumn}>
                <SingleSelect
                    id={'financialRequestServicesSingleSelect'}
                    label={'Service'}
                    placeholder="Select Service..."
                    mandatory={true}
                    disabled={
                        serviceDetailsForContractId.length === 0 ||
                        !selects.contract ||
                        !selects.contract.id
                    }
                    menuItems={serviceDetailsForContractId || []}
                    selectedId={selects.serviceId || ''}
                    selected={
                        serviceDetailsForContractId.find((el) => el.id === selects.serviceId) || {}
                    }
                    error={errors.serviceId}
                    onChange={(id) => onChange('serviceId', id)}
                />
            </div>
        </div>
    );
};

export const ModalActionFinancialRequest = ({ onPresent, disabled }) => {
    return <Button id="presentDataSet" disabled={disabled} content="Present" onClick={onPresent} />;
};

export default ModalContentFinancialRequest;

ModalContentFinancialRequest.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.object,
    selects: PropTypes.object,
    onChange: PropTypes.func
};

ModalActionFinancialRequest.propTypes = {
    onPresent: PropTypes.func,
    disabled: PropTypes.bool
};
