export const modalContentAttendanceOutcomeValidationField = {
    attendanceStatuses: {
        subject: 'Attendance status',
        required: 'select'
    },
    contract: {
        subject: 'Contract',
        required: 'select'
    }
};
