import { commonFieldValidations } from '../../../utils/formValidation/commonFieldValidations';
import { charVal } from '../../../utils/formValidation/validationPatterns';

export const courseTemplateValidationFields = {
    name: {
        required: 'text',
        subject: 'Course name',
        charMin: 5,
        charMax: charVal.MAX_NAME
    },
    description: {
        required: 'text',
        subject: 'Course description',
        charMin: 50
    },
    contractIds: commonFieldValidations.contractId,
    healthInformationIds: {
        conditional: [
            {
                condition: (entry) => entry.healthInformationCaptured,
                required: 'select',
                subject: 'health condition'
            }
        ]
    },
    moduleName: {
        conditional: [
            {
                condition: (entry) => !entry.modularCourse,
                required: 'text',
                subject: 'Module name',
                charMin: 15,
                charMax: charVal.MAX_NAME
            }
        ]
    },
    moduleDescription: {
        conditional: [
            {
                condition: (entry) => !entry.modularCourse,
                required: 'text',
                subject: 'Module description',
                charMin: 50
            }
        ]
    },
    smartTargetActionName: {
        conditional: [
            {
                condition: (entry) => !entry.modularCourse && entry.smartTargetFlag,
                required: 'text',
                subject: 'Action',
                charMin: 15,
                charMax: charVal.MAX_NAME
            }
        ]
    },
    smartTargetBarrierName: {
        conditional: [
            {
                condition: (entry) => !entry.modularCourse && entry.smartTargetFlag,
                required: 'select',
                subject: 'barrier name'
            }
        ]
    },
    smartTargetActionDescription: {
        conditional: [
            {
                condition: (entry) => !entry.modularCourse && entry.smartTargetFlag,
                required: 'text',
                subject: 'Action description',
                charMin: 50
            }
        ]
    }
};
