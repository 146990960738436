import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';

import form from '../../../commonStyles/formStyles.module.css';

export const initialReferralsCreatedStatus = {
    contract: {}
};

const ModalContentReferralsCreated = ({ contracts = [], errors, selects, onChange }) => {
    return (
        <div className={form.formSection}>
            <SingleSelect
                id="contractId"
                label="Contract"
                placeholder="Select Contract..."
                mandatory={true}
                menuItems={contracts}
                selectedId={selects.contract?.id || ''}
                selected={
                    (selects.contract?.id &&
                        contracts.find((el) => el.id === selects.contract.id)) ||
                    {}
                }
                error={errors.contract}
                onChange={(id) =>
                    onChange(
                        'contract',
                        contracts.find((el) => el.id === id)
                    )
                }
            />
        </div>
    );
};

export const ModalActionReferralsCreated = ({ onPresent, disabled }) => {
    return <Button id="presentDataSet" disabled={disabled} content="Present" onClick={onPresent} />;
};

export default ModalContentReferralsCreated;

ModalContentReferralsCreated.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.object,
    selects: PropTypes.object,
    onChange: PropTypes.func
};

ModalActionReferralsCreated.propTypes = {
    onPresent: PropTypes.func,
    disabled: PropTypes.bool
};
