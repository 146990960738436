import React from 'react';
import PropTypes from 'prop-types';

import RestoreIcon from '@mui/icons-material/Restore';

import { generateUniqueId } from '../../utils/stringUtils';
import IconError from '../IconError';

import common from './formElementStyles/commonFormElements.module.css';
import dates from './formElementStyles/dateSelect.module.css';

const DateSelect = ({
    id = `date-label_${generateUniqueId()}`,
    value,
    onDateChange,
    label = 'Date',
    disabled = false,
    isDefault = false,
    mandatory = false,
    customClass = '',
    error = {},
    onSearchAuditItems,
    isAuditable = false,
    max = ''
}) => {
    // LOCAL STATE
    const defaultDate = String(new Date().toISOString().split('T')[0]);

    // HELPER FNS
    return (
        <div className={`${common.fieldWrapper} ${dates[customClass]} ${dates.dateInput}`}>
            <label htmlFor={id} className={common.formLabel}>
                {label} {mandatory && <sup>*</sup>}{' '}
                {isAuditable && (
                    <div>
                        <RestoreIcon onClick={() => onSearchAuditItems(label)} />
                    </div>
                )}
            </label>
            <input
                type="date"
                id={id}
                data-testid={`dateSelectInput-${id}`}
                value={value || (isDefault ? defaultDate : '')}
                name="date"
                disabled={disabled}
                className={common.fieldInput}
                max={max}
                onChange={(e) => {
                    onDateChange(e.target.value);
                }}
            />
            {error?.error && (
                <div className={dates.errorWrapper}>
                    <div className={dates.error}>
                        <IconError text={error} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateSelect;

DateSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onDateChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    isDefault: PropTypes.bool,
    customClass: PropTypes.string,
    error: PropTypes.object,
    onSearchAuditItems: PropTypes.func,
    isAuditable: PropTypes.bool,
    max: PropTypes.string
};
