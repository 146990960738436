import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Card, Typography } from '@mui/material';

import { setHeader, setURLs } from '../../api/commonHTTP';
import { loadUserAttributes, setIdToken } from '../../store/userService';
import {
    POLARIS_BLUE,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREEN,
    POLARIS_MIKADO_YELLOW,
    POLARIS_ORANGE,
    POLARIS_TOTTENHAM_TEAL,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../themes/theme';

/**
 * Shown near top right of Header
 * This shows the current environment
 *
 * An index to the current environment is currently saved in UserService
 *
 * Possible environments are defined in 'environment'
 *
 *
 */
// eslint-disable-next-line no-undef
const BUILD_NUMBER = ' - Build:' + process.env.REACT_APP_BUILD_NUMBER;

export const environment = [
    {
        name: 'DEV',
        text: 'Development',
        backgroundColor: POLARIS_TOTTENHAM_TEAL,
        color: POLARIS_WHITE,
        calendarUrl: 'http://localhost:9000/calendar-service/',
        communicationUrl: 'http://localhost:9000/communication-service/',
        courseUrl: 'http://localhost:9000/course-service/',
        directusUrl: 'http://localhost:9000/directus-service/',
        documentUrl: 'http://localhost:9000/document-service/',
        participantUrl: 'http://localhost:9000/participant-service/',
        recruitmentUrl: 'http://localhost:9000/recruitment-service/',
        userUrl: 'http://localhost:9000/user-service/'
    },
    {
        name: 'PROD',
        text: 'Production' + BUILD_NUMBER,
        backgroundColor: POLARIS_WHITE,
        color: POLARIS_BLUE,
        baseUrl: 'https://prod.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/'
    },
    {
        name: 'TEST',
        text: 'Test' + BUILD_NUMBER,
        backgroundColor: POLARIS_ORANGE,
        color: POLARIS_WHITE,
        baseUrl: 'https://test.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/'
    },
    {
        name: 'UAT',
        text: 'UAT' + BUILD_NUMBER,
        backgroundColor: POLARIS_GREEN,
        color: POLARIS_WHITE,
        baseUrl: 'https://uat.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/'
    },
    {
        name: 'SANDBOX',
        text: 'Sandbox',
        backgroundColor: POLARIS_MIKADO_YELLOW,
        color: POLARIS_BLUE,
        // eslint-disable-next-line no-undef
        calendarUrl: process.env.REACT_APP_CALENDAR_SERVICE_URL,
        // eslint-disable-next-line no-undef
        communicationUrl: process.env.REACT_APP_COMMUNICATION_SERVICE_URL,
        // eslint-disable-next-line no-undef
        courseUrl: process.env.REACT_APP_COURSE_SERVICE_URL,
        // eslint-disable-next-line no-undef
        directusUrl: process.env.REACT_APP_DIRECTUS_SERVICE_URL,
        // eslint-disable-next-line no-undef
        documentUrl: process.env.REACT_APP_DOCUMENT_SERVICE_URL,
        // eslint-disable-next-line no-undef
        participantUrl: process.env.REACT_APP_PARTICIPANT_SERVICE_URL,
        // eslint-disable-next-line no-undef
        recruitmentUrl: process.env.REACT_APP_RECRUITMENT_SERVICE_URL,
        // eslint-disable-next-line no-undef
        userUrl: process.env.REACT_APP_USER_SERVICE_URL
    },
    {
        name: 'TRN',
        text: 'Training' + BUILD_NUMBER,
        backgroundColor: POLARIS_DOUBLE_DECKER_RED,
        color: POLARIS_WHITE,
        baseUrl: 'https://trn.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/'
    }
];

const General = () => {
    // Get the current environment

    const environmentIndex = useSelector(
        (state) => state.entities.userService.loggedInUser.environment
    );
    const { accessToken, idToken } = useSelector((state) => state.entities.userService);

    const backgroundColor = environment[environmentIndex].backgroundColor;
    const color = environment[environmentIndex].color;
    const text = environment[environmentIndex].text;

    setURLs(environment[environmentIndex]);

    const { instance } = useMsal();
    const dispatch = useDispatch();

    const setHeaderToken = (token) => {
        setHeader({
            Authorization: 'Bearer ' + token + '',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        });
    };

    const refreshToken = () => {
        var request = {
            scopes: []
        };

        instance
            .acquireTokenSilent(request)
            .then((tokenResponse) => {
                setHeaderToken(tokenResponse.idToken);
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(request);
                }
            });
    };

    useEffect(() => {
        // first time login, use generated MASL ID (access) token
        if (idToken) {
            setHeaderToken(idToken);
        } else {
            // no idToken, so treat as a refresh and get accessToken from localStorage
            setHeaderToken(accessToken);
            dispatch(setIdToken(accessToken));
            refreshToken();
        }

        // load user attributes if we have a token available
        if (idToken || accessToken) {
            dispatch(loadUserAttributes());
        }
    }, [idToken, accessToken]);

    return (
        <Card
            sx={{
                backgroundColor: backgroundColor,
                color: color,
                borderRadius: SCREEN_SETTINGS.radius.medium,
                paddingLeft: SCREEN_SETTINGS.padding.huge,
                paddingRight: SCREEN_SETTINGS.padding.huge
            }}
            elevation={0}>
            <Typography
                variant="body1"
                onClick={() => {
                    window.open(
                        'https://github.com/ReedInPartnership/polaris-front-end/commit/' +
                            // eslint-disable-next-line no-undef
                            process.env.REACT_APP_BUILD_SHA,
                        '_blank',
                        'noopener,noreferrer'
                    );
                }}
                component="div"
                sx={{ flexGrow: 1 }}>
                {text}
            </Typography>
        </Card>
    );
};
export default General;
