import PropTypes from 'prop-types';

import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import {
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';

import ModuleTemplateEditor from './ModuleTemplateEditor';

import classes from '../cardStyles/cardView.module.css';

/**
 * Viewer for smart target card
 *
 * Uses ModuleTemplateEditor when edit icon is clicked
 *
 * Calls the sendDelete callback when delete icon is clicked and this causes
 * CardHandler to delete this item.
 *
 * @param cardData Data for this viewer. This has values which match the
 *     module-template table in the course service
 * @param sendUpdate  Callback function to tell parent to update cardData.
 * @param validate  Checks if card is valid
 * @param disabled  If true, disables control.
 * @param barrierNameDetails  Barrier information.
 * @returns {JSX.Element}
 *
 */

const ModuleTemplateViewer = ({
    cardData,
    sendUpdate,
    validate,
    disabled = false,
    barrierNameDetails
}) => {
    // LOCAL STATE
    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_ROYAL_BLUE;

    // HELPER FUNCTIONS
    const getName = (id) => {
        if (!id) return '';
        const selectedBarrier = barrierNameDetails.find((item) => item.id === id);
        return selectedBarrier?.name || '';
    };

    const getCardContent = () => {
        if (
            !validate({
                ...cardData,
                smartTargetFlag: cardData.smartTarget === null ? false : cardData.smartTargetFlag
            })
        ) {
            return (
                <Typography variant="body1" sx={{ color: POLARIS_DOUBLE_DECKER_RED }}>
                    Incomplete Module Template Details
                </Typography>
            );
        }

        return (
            <div style={{ width: '250 px' }}>
                <Typography variant="body1">
                    <strong>Module Description: </strong>
                    {cardData.description.length > 100
                        ? cardData.description.substring(0, 97) + '...'
                        : cardData.description}
                </Typography>
                {cardData.smartTarget && (
                    <>
                        <Typography variant="body1">
                            <strong>Action Name: </strong>
                            {cardData.smartTarget.name}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Action Barrier: </strong>
                            {cardData.smartTarget.barrierNameId
                                ? getName(cardData.smartTarget.barrierNameId)
                                : ''}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Action Description: </strong>
                            {cardData.smartTarget.action.length > 200
                                ? cardData.smartTarget.action.substring(0, 197) + '...'
                                : cardData.smartTarget.action}
                        </Typography>
                    </>
                )}
            </div>
        );
    };

    // EVENT HANDLERS
    const handleChange = (data) => {
        sendUpdate(data);
    };

    // RENDER
    return (
        <div className={classes.cardView}>
            <Card
                elevation={3}
                sx={{
                    p: 0,
                    backgroundColor: POLARIS_WHITE,
                    width: 250,
                    height: 'calc(100% - 16px)',
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <CardHeader
                    title={
                        <div
                            className={`${
                                cardData.name?.length > 60
                                    ? classes.bannerDoubleRow
                                    : classes.banner
                            }`}>
                            <Typography variant="h6">
                                {cardData.name || 'Module Template'}
                            </Typography>
                        </div>
                    }
                    sx={{
                        p: 0.8,
                        m: 0.8,
                        backgroundColor: { backgroundColor },
                        color: POLARIS_WHITE
                    }}
                    action={
                        <Stack direction="row">
                            <ModuleTemplateEditor
                                barrierNameDetails={barrierNameDetails}
                                disabled={disabled}
                                sendChange={handleChange}
                                cardData={cardData}></ModuleTemplateEditor>{' '}
                        </Stack>
                    }
                />
                <CardContent sx={{ padding: 1 }}>{getCardContent()}</CardContent>
            </Card>
        </div>
    );
};

ModuleTemplateViewer.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    barrierNameDetails: PropTypes.array.isRequired,
    sendUpdate: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
};

export default ModuleTemplateViewer;
