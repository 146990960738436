import { validate } from '../../../utils/formValidation/validator';
import { moduleTemplateEditorValidationFields } from '../../ui/cards/moduleTemplate/ModuleTemplateEditorValidationFields';

import { courseTemplateValidationFields } from './CourseTemplateValidationFields';

export const validateCourseTemplate = (newEntry, singleModule) =>
    validate(
        {
            ...newEntry,
            moduleName: singleModule.name,
            moduleDescription: singleModule.description,
            smartTargetFlag: !!singleModule.smartTarget,
            smartTargetActionName: singleModule.smartTarget?.name,
            smartTargetBarrierName: singleModule.smartTarget?.barrierNameId,
            smartTargetActionDescription: singleModule.smartTarget?.action
        },
        courseTemplateValidationFields
    );

export const validateModuleCard = (card) => {
    const validation = validate(
        {
            ...card,
            smartTargetFlag: !!card.smartTarget,
            smartTargetActionName: card.smartTarget?.name,
            smartTargetBarrierNameId: card.smartTarget?.barrierNameId,
            smartTargetActionDescription: card.smartTarget?.action
        },
        moduleTemplateEditorValidationFields
    );
    return validation.isValid;
};

export const hasIncompleteCard = (newEntry) =>
    newEntry.moduleTemplates.some((el) => !validateModuleCard(el));

export const duplicateModuleNames = (newEntry) => {
    const moduleTemplateNames = [
        ...new Set(newEntry.moduleTemplates.map((item) => item.name.toLowerCase()))
    ];
    return moduleTemplateNames.length < newEntry.moduleTemplates.length;
};
