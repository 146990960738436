import { getTodayOrFutureDate } from '../../utils/dateFunctions';
import { getSelectValueMessage } from '../../utils/formValidation/errorMessageFunctions';

const MIN_NAME_CHAR = 1;
const MAX_NAME_CHAR = 100;
const MAX_CLAIM_FILE_PREFIX_CHAR = 15;

export const NAME_MIN = `Service name must be at least ${MIN_NAME_CHAR} character`;
export const NAME_MAX = `Service name must be ${MAX_NAME_CHAR} characters or less`;
export const NO_CONTRACT_ID = 'Please enter a contract';
export const COMPANY_NAME_MAX = `Company name must be ${MAX_NAME_CHAR} characters or less`;
export const ADVISER_AUTH_1 = 'Invalid Amount';

export const CLAIM_FILE_PREFIX = `Claim file prefix name must be ${MAX_CLAIM_FILE_PREFIX_CHAR} characters or less`;
export const DATE_RANGE_INVALID_1 = 'Required when Active is yes';
export const DATE_RANGE_INVALID_2 = 'Must be before service end date';
export const DATE_RANGE_INVALID_3 = 'Must be today or future date';

export const initialErrorState = {
    name: { error: false, message: '' },
    contract: { error: false, message: '' },
    company_name: { error: false, message: '' },
    adviser_authorisation_limit: { error: false, message: '' },
    start_date: { error: false, message: '' },
    end_date: { error: false, message: '' },
    claim_file_prefix: { error: false, message: '' },
    active: { error: false, message: '' }
};

export const validate = (newEntry, errors, serviceDetails) => {
    let isValid = true;
    let newErrors = errors;

    if (serviceDetails?.some((el) => el.name?.toLowerCase() === newEntry.name?.toLowerCase())) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: `Service ${newEntry.name} already exists.` }
        };
        isValid = false;
    } else if (!newEntry.name?.trim().length >= MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: NAME_MIN }
        };
        isValid = false;
    } else if (newEntry.name?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: NAME_MAX }
        };
        isValid = false;
    }
    if (!newEntry.contract) {
        newErrors = {
            ...newErrors,
            contract: { error: true, message: NO_CONTRACT_ID }
        };
        isValid = false;
    }
    if (newEntry.company_name?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            company_name: { error: true, message: COMPANY_NAME_MAX }
        };
        isValid = false;
    }
    if (
        newEntry.adviser_authorisation_limit &&
        !newEntry.adviser_authorisation_limit?.match(/^\d{0,4}(\.\d{1,2})?$/i)
    ) {
        newErrors = {
            ...newErrors,
            adviser_authorisation_limit: { error: true, message: ADVISER_AUTH_1 }
        };
        isValid = false;
    }
    if (newEntry.active && !newEntry.start_date) {
        newErrors = {
            ...newErrors,
            start_date: { error: true, message: DATE_RANGE_INVALID_1 }
        };
    } else if (newEntry.active && !newEntry.end_date) {
        newErrors = {
            ...newErrors,
            end_date: { error: true, message: DATE_RANGE_INVALID_1 }
        };
    } else if (newEntry.active && newEntry.end_date <= newEntry.start_date) {
        newErrors = {
            ...newErrors,
            start_date: { error: true, message: DATE_RANGE_INVALID_2 }
        };
        isValid = false;
    } else
        newErrors = {
            ...newErrors,
            start_date: initialErrorState.start_date
        };

    if (newEntry.active == null) {
        newErrors = {
            ...newErrors,
            active: { error: true, message: getSelectValueMessage() }
        };
        isValid = false;
    }

    if (newEntry.active) newErrors = checkDate(newEntry.end_date, newErrors);

    if (newErrors.end_date.error) isValid = false;

    if (newEntry.claim_file_prefix?.trim().length > MAX_CLAIM_FILE_PREFIX_CHAR) {
        newErrors = {
            ...newErrors,
            claim_file_prefix: { error: true, message: CLAIM_FILE_PREFIX }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};

export const checkDate = (date, errors) => {
    return getTodayOrFutureDate(date)
        ? {
              ...errors,
              end_date: initialErrorState.end_date
          }
        : {
              ...errors,
              end_date: { error: true, message: DATE_RANGE_INVALID_3 }
          };
};
