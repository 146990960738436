import { charVal } from '../../../../utils/formValidation/validationPatterns';

export const moduleTemplateEditorValidationFields = {
    name: {
        required: 'text',
        subject: 'Module name',
        charMin: 15,
        charMax: charVal.MAX_NAME
    },
    description: {
        required: 'text',
        subject: 'Module description',
        charMin: 50
    },
    smartTargetActionName: {
        conditional: [
            {
                condition: (entry) => entry.smartTargetFlag,
                required: 'text',
                subject: 'Action',
                charMin: 15,
                charMax: charVal.MAX_NAME
            }
        ]
    },
    smartTargetBarrierNameId: {
        conditional: [
            {
                condition: (entry) => entry.smartTargetFlag,
                required: 'select',
                subject: 'barrier name'
            }
        ]
    },
    smartTargetActionDescription: {
        conditional: [
            {
                condition: (entry) => entry.smartTargetFlag,
                required: 'text',
                subject: 'Action description',
                charMin: 50
            }
        ]
    }
};
