import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {
    createUpdateAutomatedMessages,
    loadAutomatedMessages,
    searchCommunicationTemplates
} from '../../../store/communicationService';
import { loadLocationsForServices } from '../../../store/directusService';
import { loadServiceConstants, updateServiceConstants } from '../../../store/participantService';
import { daysToSeconds, secondsToDays } from '../../../utils/dateFunctions';
import { updateDirectusServiceSelections } from '../../../utils/directusFunctions';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import Switch from '../../formElements/Switch';
import TextInputField from '../../formElements/TextInputField';
import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';

import AddressAndContactInfo from './AddressAndContactInfo';
import AutomatedMessagesAdmin from './AutomatedMessagesAdmin';
import { BUTTON_ERROR, initialErrorState, validate } from './validateServiceConfig';

import form from '../../../commonStyles/formStyles.module.css';
import local from './configureServiceStyles.module.css';

const ConfigureService = ({
    service,
    serviceIds,
    allLocations,
    locationsForServices,
    roles,
    clear
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialConstants = {
        id: '',
        serviceId: service.id,
        participantVoiceCourse: false,
        participantVoiceAppointment: false,
        participantVoiceGracePeriod: '',
        participantVoiceIsService: false,
        serviceIdCode: '',
        formAddressLine1: '',
        formAddressLine2: '',
        formAddressLine3: '',
        formAddressLine4: '',
        formCity: '',
        formCounty: '',
        formPostcode: '',
        formPhoneNumber: '',
        exitFormEmailAddress: '',
        sanctionFormFromEmailAddress: '',
        serviceAddressLine1: '',
        serviceAddressLine2: '',
        serviceAddressLine3: '',
        serviceAddressLine4: '',
        serviceCity: '',
        serviceCounty: '',
        servicePostcode: ''
    };
    const acceptedRoles = [SUPERUSER];
    const submitLocationsMessage = `Updating locations for ${service.name}`;
    const submitConstantsMessage = `Updating service constants for ${service.name}`;
    const submitAutomatedMessages = `Automated messages for ${service.name} have been updated`;
    const [existingConstants, setExistingConstants] = useState(initialConstants);
    const [constants, setConstants] = useState(initialConstants);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [preSelectedLocations, setPreSelectedLocations] = useState([]);
    const [preSelectedLocationIds, setPreSelectedLocationIds] = useState([]);
    const [automatedMessages, setAutomatedMessages] = useState({});
    const [existingAutomatedMessages, setExistingAutomatedMessages] = useState({});
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const serviceConstants = useSelector(
        (state) => state.entities.participantService.serviceConstants
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USEEFFECTS

    useEffect(() => {
        if (!locationsForServices || locationsForServices.length < 1) return;
        const serviceLocations = locationsForServices.filter((el) =>
            el.service.find((entry) => entry?.service_id === service.id)
        );
        const ids = serviceLocations.map((el) => el.id);
        setPreSelectedLocations(serviceLocations);
        setPreSelectedLocationIds(ids);
        setSelectedLocations(ids);
    }, [locationsForServices]);

    useEffect(() => {
        if (!service || constants.id) return;
        dispatch(loadServiceConstants(service.id));
        dispatch(loadAutomatedMessages(service.id));
        dispatch(
            searchCommunicationTemplates({
                serviceIds: [service.id],
                inactive: false
            })
        );
    }, [service]);

    useEffect(() => {
        if (!serviceConstants[service.id]) return;
        const constantsForService = serviceConstants[service.id];
        setConstants({
            ...constantsForService,
            participantVoiceGracePeriod: constantsForService.participantVoiceGracePeriod
                ? secondsToDays(constantsForService.participantVoiceGracePeriod)
                : ''
        });
        setExistingConstants(serviceConstants[service.id]);
    }, [serviceConstants]);

    useEffect(() => {
        clear && clearFields();
    }, [clear]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage.includes(submitLocationsMessage)) {
            dispatch(loadLocationsForServices(serviceIds));
        }
        if (successMessage === submitConstantsMessage) {
            dispatch(loadServiceConstants(service.id));
        }
    }, [successMessage]);

    // HELPER FNS

    const clearFields = () => {
        selectedLocations.length > 0 && setSelectedLocations([]);
        preSelectedLocations.length > 0 && setPreSelectedLocations([]);
        preSelectedLocationIds.length > 0 && setPreSelectedLocationIds([]);
        setConstants(initialConstants);
        setExistingConstants({});
        setExistingAutomatedMessages({});
        setAutomatedMessages({});
        setErrors(initialErrorState);
    };

    const clearError = (key) =>
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false, message: '' } }));

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        if (`${service.id}-${key}` in errors) clearError(`${service.id}-${key}`);
        setConstants((prev) => ({ ...prev, [key]: value }));
    };

    const onLocationsChange = (chosenIds) => {
        clearError(`${service.id}-location`);
        setSelectedLocations(chosenIds || []);
    };
    const onSwitchType = (e) => {
        const { checked } = e.target;
        setConstants((prev) => ({ ...prev, participantVoiceIsService: checked }));
    };

    const onParticipantVoiceGracePeriodChange = (e) => {
        clearError(`${service.id}-participantVoiceGracePeriod`);
        setConstants((prev) => ({
            ...prev,
            participantVoiceGracePeriod: e.target.value
                .replace(/[^\d]/g, '')
                .replace(/^0+/, '')
                .slice(0, 7)
        }));
    };

    const onServiceIdCodeChange = (e) => {
        setConstants((prev) => ({
            ...prev,
            serviceIdCode: e.target.value.replace(/[^A-Z\d]/gi, '').slice(0, 20)
        }));
    };

    const onExistingAutomatedMessagesLoad = (entries) => {
        setExistingAutomatedMessages(entries);
        setAutomatedMessages(entries);
    };

    const onAutomatedMessagesChange = (entries) => setAutomatedMessages(entries);

    const onSubmit = (e) => {
        e.preventDefault();
        const finalConstants = {
            ...constants,
            participantVoiceGracePeriod: constants.participantVoiceGracePeriod
                ? daysToSeconds(constants.participantVoiceGracePeriod)
                : ''
        };

        const { newErrors, isAutomatedMessagesSame, isConstantsSame } = validate(
            service.id,
            selectedLocations,
            finalConstants,
            automatedMessages,
            existingAutomatedMessages,
            existingConstants
        );
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const locationChanges = updateDirectusServiceSelections(
            'location',
            preSelectedLocationIds,
            preSelectedLocations,
            selectedLocations,
            service.id,
            submitLocationsMessage,
            dispatch
        );

        if (isConstantsSame && isAutomatedMessagesSame && locationChanges === 0) {
            setErrors((prev) => ({ ...prev, button: { error: true, message: BUTTON_ERROR } }));
            return;
        }

        if (!isAutomatedMessagesSame) {
            const config = Object.values(automatedMessages).map((el) => ({
                id: el?.id || '',
                type: el.type,
                enabled: el.enabled,
                communicationTemplateId: el.communicationTemplateId,
                numDays: el.numDays ? +el.numDays : null,
                serviceId: service.id
            }));
            const payload = { serviceId: service.id, automatedMessageConfigurations: config };
            dispatch(createUpdateAutomatedMessages(payload, submitAutomatedMessages));
        }

        if (!isConstantsSame) {
            dispatch(updateServiceConstants(finalConstants, submitConstantsMessage));
        }
    };

    // RENDER

    return (
        <>
            <Accordion
                key={`${service.id}-address-and-contact-info`}
                slotProps={{ transition: { mountOnEnter: true, unmountOnExit: true } }}
                hidden={!hasRole([SUPERUSER], roles)}>
                <AccordionSummary
                    key={`accordionSummary-AddressAndContactInfo`}
                    expandIcon={<AccordionIcon />}
                    aria-controls="panel3b-content"
                    id="panel3b-header">
                    <AccordionHeader>Addresses & Contact Information</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <AddressAndContactInfo serviceId={service.id} />
                </AccordionDetails>
            </Accordion>
            <form className={form.formWrapper} onSubmit={onSubmit} data-testid="form_start">
                <div className={local.configServiceSection}>
                    <h3>Service - {service.name} </h3>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <MultiSelect
                                id={`${service.id}-location`}
                                label="Locations"
                                placeholder="Select locations"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                menuItems={allLocations || []}
                                preSelectedIds={preSelectedLocationIds || []}
                                preSelects={preSelectedLocations || []}
                                selectAllDisabled={true}
                                error={errors[`${service.id}-location`]}
                                onChange={(chosenIds) => onLocationsChange(chosenIds)}
                            />
                        </div>
                    </div>

                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <RadioButtons
                                id={`${service.id}-participantVoiceCourse`}
                                label="Participant Voice for Course Modules"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={constants.participantVoiceCourse || false}
                                onChange={(option) => onUpdate('participantVoiceCourse', option)}
                            />
                            <RadioButtons
                                id={`${service.id}-participantVoiceAppointment`}
                                label="Participant Voice for Appointments"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={constants.participantVoiceAppointment || false}
                                onChange={(option) =>
                                    onUpdate('participantVoiceAppointment', option)
                                }
                            />
                        </div>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={`${service.id}-participantVoiceGracePeriod`}
                                label={'Participant Voice Grace Period'}
                                placeholder={'Enter number of days'}
                                value={constants.participantVoiceGracePeriod || ''}
                                error={errors[`${service.id}-participantVoiceGracePeriod`]}
                                onChange={(e) => onParticipantVoiceGracePeriodChange(e)}
                            />

                            <Switch
                                id={`${service.id}-participantVoiceIsService`}
                                label="Send Service or Contract for Participant Voice"
                                leftLabel="Contract"
                                rightLabel="Service"
                                checked={constants.participantVoiceIsService || false}
                                onChange={onSwitchType}
                            />
                        </div>
                    </div>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={`${service.id}-serviceIdCode`}
                                label={'Service ID Code'}
                                placeholder={'Enter service ID code'}
                                value={constants.serviceIdCode || ''}
                                onChange={onServiceIdCodeChange}
                            />
                        </div>
                        <div className={form.formColumn}></div>
                    </div>

                    <AutomatedMessagesAdmin
                        serviceId={service.id}
                        onAutomatedMessagesChange={onAutomatedMessagesChange}
                        errors={errors}
                        clearError={clearError}
                        onExistingAutomatedMessagesLoad={onExistingAutomatedMessagesLoad}
                        clear={clear}
                    />
                </div>
                <Button
                    id="configureService"
                    content="UPDATE SERVICE"
                    disabled={isDisabled}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </>
    );
};

export default ConfigureService;

ConfigureService.propTypes = {
    contractId: PropTypes.string,
    service: PropTypes.object.isRequired,
    serviceIds: PropTypes.arrayOf(PropTypes.string),
    allLocations: PropTypes.arrayOf(PropTypes.object),
    locationsForServices: PropTypes.arrayOf(PropTypes.object),
    roles: PropTypes.arrayOf(PropTypes.string),
    onUpdateServiceLocations: PropTypes.func,
    onUpdateConstants: PropTypes.func,
    onLoadExistingConstants: PropTypes.func,
    errors: PropTypes.object,
    clear: PropTypes.bool,
    clearError: PropTypes.func
};
