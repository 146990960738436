import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setErrorMessage } from '../../../store/formsState';

import app from '../../../app.module.css';
import error from './messageBanners.module.css';

const ErrorSnackbar = () => {
    const dispatch = useDispatch();

    const WAIT = 5000;
    const [isOpen, setIsOpen] = useState(false);

    const message = useSelector((state) => state.entities.formsState.errorMessage);

    useEffect(() => {
        setIsOpen(message.length > 0);
    }, [message]);

    useEffect(() => {
        if (!isOpen) return;
        const el = document.getElementById('errorMessageEl');
        el && setTimeout(() => el.classList.add(`${app.fadeout}`), WAIT - 500);
        setTimeout(() => onClose(), WAIT);
    }, [isOpen]);

    const onClose = () => {
        dispatch(setErrorMessage(''));
    };

    return !isOpen ? null : (
        <div
            id="errorMessageEl"
            className={`${error.bannerWrapper} ${error.errorWrapper} ${app.fadein}`}
            onClick={onClose}>
            Error: {message}
        </div>
    );
};

export default ErrorSnackbar;
