import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';

import { stableSort } from '../../../utils/sortFunctions';
import { generateUniqueId } from '../../../utils/stringUtils';
import { DEFAULT_TABLE_ROW_OPTIONS, DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';

import TimelineEventHistoryTableRow from './TimelineEventHistoryTableRow';

const headCells = [
    { id: 'field', numeric: false, label: 'Field' },
    { id: 'from', numeric: false, label: 'From' },
    { id: 'to', numeric: false, label: 'To' }
];

const TimelineEventHistoryTable = ({ rows }) => {
    // LOCAL STATE
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);

    // USE EFFECTS
    useEffect(() => {
        setOrder('desc');
        setOrderBy('date');
    }, []);

    // EVENT HANDLERS
    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    // RENDER
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow key={`TimelineEventHistoryTableHeader`}>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => onSort(headCell.id)}>
                                            <Typography variant={'subtitle4'} color={'primary'}>
                                                {headCell.label}
                                            </Typography>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc'
                                                        ? 'sorted descending'
                                                        : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort(rows, orderBy, order)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((el) => (
                                    <TimelineEventHistoryTableRow
                                        key={generateUniqueId()}
                                        row={el}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default TimelineEventHistoryTable;

TimelineEventHistoryTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
