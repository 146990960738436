import React from 'react';
import { Outlet } from 'react-router-dom';

import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import ErrorSnackbar from './components/ui/messageBanners/ErrorSnackBar';
import SuccessSnackbar from './components/ui/messageBanners/SuccessSnackbar';
import { msalInstance } from './masl/authConfig';
import Masl from './masl/Masl';
import { appTheme } from './themes/theme';
import { reactPlugin } from './ApplicationInsightsService';

import classes from './app.module.css';

const App = () => {
    return (
        <MsalProvider instance={msalInstance}>
            <Masl />

            <div className={classes.wrapper}>
                <ThemeProvider theme={appTheme}>
                    <div className={classes.app}>
                        <CssBaseline enableColorScheme />

                        <AppInsightsContext.Provider value={reactPlugin}>
                            <div className={classes.header}>
                                <Header />
                            </div>
                            <div className={classes.content}>
                                <div className={classes.outletWrapper}>
                                    <AuthenticatedTemplate>
                                        <Outlet />
                                    </AuthenticatedTemplate>
                                </div>
                            </div>

                            <SuccessSnackbar />
                            <ErrorSnackbar />
                            <Footer />
                        </AppInsightsContext.Provider>
                    </div>
                </ThemeProvider>
            </div>
        </MsalProvider>
    );
};

export default App;
