import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import OnEditIcon from '../../../../icons/OnEditIcon';
import { POLARIS_WHITE } from '../../../../themes/theme';
import { getEmptyErrorState, validate } from '../../../../utils/formValidation/validator';
import { clearKeys } from '../../../../utils/objectUtils';
import RadioButtons from '../../../formElements/RadioButtons';
import SingleSelect from '../../../formElements/SingleSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import TextInputField from '../../../formElements/TextInputField';
import FormActions from '../../formActions/FormActions';
import SmartDefs from '../../notices/smartDefs/SmartDefs';

import { moduleTemplateEditorValidationFields } from './ModuleTemplateEditorValidationFields';

import app from '../../../../app.module.css';

const MODULE_DESCRIPTION_LIMIT = 250;
const ACTION_DESCRIPTION_LIMIT = 750;

/**
 * A dialog to edit module template details
 * @param cardData - Data for this editor
 * This has values which match the additional_contact_card table in the participant service
 * @param disabled
 * @param sendChange Callback function to tell parent that data has changed.
 * @param barrierNameDetails This has an array of barriers that are passed in from the viewer to avoid
 * having to fetch again.
 *
 * @returns {JSX.Element}
 */
const ModuleTemplateEditor = ({ cardData, disabled = false, sendChange, barrierNameDetails }) => {
    // LOCAL STATE
    const validationFields = { ...moduleTemplateEditorValidationFields };
    const [newEntry, setNewEntry] = useState(cardData);
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [open, setOpen] = useState(false);
    const [keys, setKeys] = useState({ smartTargetBarrierNameId: '1' });

    // USE EFFECTS
    useEffect(() => {
        if (!cardData.name && !cardData.description) {
            if (disabled === false) {
                setOpen(true);
            }
        }
    }, []);

    // HELPER FUNCTIONS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onModuleNameChange = (e) => {
        clearError('name');
        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
    };

    const onModuleDescriptionChange = (e) => {
        clearError('description');
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };

    const onSmartTargetFlagChange = (option) => {
        setNewEntry((prev) => ({
            ...prev,
            smartTarget: option
                ? {
                      name: '',
                      barrierNameId: '',
                      action: ''
                  }
                : null
        }));
        if (!option) {
            clearError('smartTargetActionName');
            clearError('smartTargetBarrierNameId');
            clearError('smartTargetActionDescription');
        }
    };

    const onSmartTargetChange = (key, value, errorKey) => {
        clearError(errorKey);
        setNewEntry((prev) => ({
            ...prev,
            smartTarget: { ...newEntry.smartTarget, [key]: value }
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validation = validate(
            {
                ...newEntry,
                smartTargetFlag: !!newEntry.smartTarget,
                smartTargetActionName: newEntry.smartTarget?.name,
                smartTargetBarrierNameId: newEntry.smartTarget?.barrierNameId,
                smartTargetActionDescription: newEntry.smartTarget?.action
            },
            validationFields
        );
        setErrors(validation.errors);
        if (!validation.isValid) return;

        sendChange(newEntry);
        setOpen(false);
    };

    const onClickOpen = () => {
        if (disabled === false) {
            setOpen(true);
        }
    };

    const onCancel = () => {
        setErrors(getEmptyErrorState(validationFields));
        setKeys(clearKeys(keys));
        setOpen(false);
    };

    return (
        <div>
            <OnEditIcon onEdit={onClickOpen} activeColor={POLARIS_WHITE} />
            <Dialog open={open} onClose={() => {}} aria-labelledby="form-dialog-title" fullWidth>
                <form onSubmit={onSubmit} data-testid="dialog_form_start">
                    <DialogTitle id="form-dialog-title">Edit Module Template</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <TextInputField
                            id={'moduleName'}
                            label={'Module Name'}
                            placeholder={'Enter module name'}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={onModuleNameChange}
                        />
                        <TextAreaField
                            id={'moduleDescription'}
                            label={'Module Description'}
                            placeholder={'Enter module description'}
                            mandatory={true}
                            maxLength={MODULE_DESCRIPTION_LIMIT}
                            value={newEntry.description}
                            count={`${newEntry.description.length}/${MODULE_DESCRIPTION_LIMIT}`}
                            error={errors.description}
                            onChange={onModuleDescriptionChange}
                        />
                        <RadioButtons
                            id={'smartTargetRadio'}
                            label={'Add Smart Target?'}
                            value={!!newEntry.smartTarget}
                            onChange={onSmartTargetFlagChange}
                        />
                        <div hidden={!newEntry.smartTarget}>
                            <h2 className={app.sectionHeading}>Smart Target Details:</h2>
                            <TextInputField
                                id={'smartTargetActionName'}
                                label={'What shall we call this action?'}
                                placeholder={'Enter the name of the action'}
                                mandatory={true}
                                value={newEntry.smartTarget?.name || ''}
                                error={errors.smartTargetActionName}
                                onChange={(e) =>
                                    onSmartTargetChange(
                                        'name',
                                        e.target.value,
                                        'smartTargetActionName'
                                    )
                                }
                            />
                            <SingleSelect
                                id={'smartTargetBarrierNameId'}
                                key={keys.smartTargetBarrierNameId}
                                label={'Which barrier name aligns to this smart target?'}
                                placeholder={'Select barrier name'}
                                mandatory={true}
                                menuItems={barrierNameDetails || []}
                                selectedId={newEntry.smartTarget?.barrierNameId || ''}
                                selected={
                                    barrierNameDetails.find(
                                        (el) => el.id === newEntry.smartTarget?.barrierNameId
                                    ) || {}
                                }
                                error={errors.smartTargetBarrierNameId}
                                onChange={(chosenId) =>
                                    onSmartTargetChange(
                                        'barrierNameId',
                                        chosenId,
                                        'smartTargetBarrierNameId'
                                    )
                                }
                            />
                            <SmartDefs />
                            <TextAreaField
                                id={'smartTargetActionDescription'}
                                label={'Action'}
                                placeholder={'Enter action description'}
                                mandatory={true}
                                maxLength={ACTION_DESCRIPTION_LIMIT}
                                value={newEntry.smartTarget?.action || ''}
                                count={`${newEntry.smartTarget?.action.length}/${ACTION_DESCRIPTION_LIMIT}`}
                                error={errors.smartTargetActionDescription}
                                onChange={(e) =>
                                    onSmartTargetChange(
                                        'action',
                                        e.target.value,
                                        'smartTargetActionDescription'
                                    )
                                }
                            />
                        </div>
                        <FormActions
                            id="publish"
                            btnText="Update"
                            disabled={disabled}
                            onClose={onSubmit}
                            onCancel={onCancel}
                        />
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    );
};

ModuleTemplateEditor.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendChange: PropTypes.func.isRequired,
    barrierNameDetails: PropTypes.array.isRequired
};
export default ModuleTemplateEditor;
