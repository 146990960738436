import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { updateCourseTemplate } from '../../../store/courseService';
import {
    clearCourseTemplateHealth,
    loadBarrierNameDetailsByContract,
    loadContractDetails,
    loadCourseTemplateHealthDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import { getEmptyErrorState } from '../../../utils/formValidation/validator';
import { clearKeys } from '../../../utils/objectUtils';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import TextAreaField from '../../formElements/TextAreaField';
import TextInputField from '../../formElements/TextInputField';
import CardHandler from '../../ui/cards/CardHandler';
import { MODULE_TEMPLATE_VIEWER } from '../../ui/cards/ViewerTypes';
import FormActions from '../../ui/formActions/FormActions';
import LoadingSpinner from '../../ui/LoadingSpinner';
import SmartDefs from '../../ui/notices/smartDefs/SmartDefs';

import {
    duplicateModuleNames,
    hasIncompleteCard,
    validateCourseTemplate,
    validateModuleCard
} from './courseTemplateUtils';
import { courseTemplateValidationFields } from './CourseTemplateValidationFields';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import pageClasses from '../courseStyles/editTemplateStyles.module.css';

const CHARACTER_LIMIT = 250;
const ACTION_DESCRIPTION_LIMIT = 750;

const EditCourseTemplate = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const validationFields = { ...courseTemplateValidationFields };
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState({});
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const [arrayHealth, setArrayHealth] = useState([]);
    const [singleModule, setSingleModule] = useState({
        name: '',
        description: '',
        smartTarget: null
    });

    const [keys, setKeys] = useState({
        healthInformation: '1',
        smartTargetBarrierName: '2'
    });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const { errorMessage, successMessage } = useSelector((state) => state.entities.formsState);
    const { contractDetails, courseTemplateHealthDetails, barrierNameDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const currentTemplate = useSelector((state) => state.entities.courseService.currentTemplate);

    // USE EFFECTS
    useEffect(() => {
        setNewEntry(currentTemplate);
        contractDetails?.length < 1 && dispatch(loadContractDetails());
        courseTemplateHealthDetails?.length < 1 && dispatch(loadCourseTemplateHealthDetails());
        dispatch(loadBarrierNameDetailsByContract(currentTemplate.contractIds));

        if (!currentTemplate.modularCourse) {
            setSingleModule(currentTemplate.moduleTemplates[0]);
        }
    }, []);

    useEffect(() => {
        if (courseTemplateHealthDetails?.length && arrayHealth?.length === 0) {
            setArrayHealth(courseTemplateHealthDetails);
        }
    }, [courseTemplateHealthDetails]);

    useEffect(() => {
        if (successMessage === `Course template details for ${newEntry.name} have been updated`) {
            setSubmitButtonDisabled(false);
            onNavigate();
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) setSubmitButtonDisabled(false);
    }, [errorMessage]);

    // HELPER FUNCTIONS
    const clearData = () => {
        dispatch(clearCourseTemplateHealth());
        setKeys(clearKeys(keys));
    };

    const createSingleModule = () => {
        dispatch(
            updateCourseTemplate({
                ...newEntry,
                moduleTemplates: [{ ...singleModule }]
            })
        );
    };

    const createMultipleModules = () => {
        const entries = newEntry.moduleTemplates
            .filter((el) => el.id)
            .map((el) => ({
                ...el,
                id: null
            }));
        dispatch(
            updateCourseTemplate({
                ...newEntry,
                moduleTemplates: entries
            })
        );
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const loadingError = () => {
        if (barrierNameDetails?.length < 1) return 'No barrier name details available';
        if (contractDetails?.length < 1) return 'No contract details available';
        if (courseTemplateHealthDetails?.length < 1)
            return 'No course template health details available';
        if (Object.keys(newEntry).length < 1) return 'Course template not found';
    };

    // EVENT HANDLERS
    const onDescriptionChange = (e) => {
        clearError('description');
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };

    const onActiveChange = (option) => setNewEntry((prev) => ({ ...prev, active: option }));

    const onHealthInformationCaptured = (option) => {
        clearError('healthInformationIds');
        setNewEntry((prev) => ({
            ...prev,
            healthInformationCaptured: option,
            healthInformationIds: option ? [] : prev.healthInformationIds
        }));
    };

    const onHealthChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setNewEntry((prev) => ({ ...prev, healthInformationIds: chosenIds }));
        if (chosenIds.length !== 0) clearError('healthInformationIds');
    };

    const onModuleDescriptionChange = (e) => {
        clearError('moduleDescription');
        setSingleModule((prev) => ({ ...prev, description: e.target.value }));
    };

    const onModulesOrderChange = (option) =>
        setNewEntry((prev) => ({ ...prev, modulesInSetOrder: option }));

    const onSmartTargetFlagChange = (option) => {
        setSingleModule((prev) => ({
            ...prev,
            smartTarget: option
                ? {
                      name: '',
                      barrierNameId: '',
                      action: ''
                  }
                : null
        }));
        if (!option) {
            clearError('smartTargetActionName');
            clearError('smartTargetBarrierName');
            clearError('smartTargetActionDescription');
        }
    };

    const onSmartTargetChange = (key, value, errorKey) => {
        clearError(errorKey);
        setSingleModule((prev) => ({
            ...prev,
            smartTarget: { ...prev.smartTarget, [key]: value }
        }));
    };

    const addModuleTemplate = () => {
        if (newEntry.moduleTemplates.some((c) => !c.name || !c.description)) {
            dispatch(setErrorMessage(`Cannot add another blank. Please complete existing card.`));
            return;
        }
        setNewEntry((prev) => ({
            ...prev,
            moduleTemplates: [
                ...newEntry.moduleTemplates,
                {
                    id: uuid(),
                    name: '',
                    description: '',
                    smartTarget: null
                }
            ]
        }));
    };

    const updateModuleTemplate = (moduleTemplate) => {
        setNewEntry((prev) => ({
            ...prev,
            moduleTemplates: newEntry.moduleTemplates.map((el) =>
                el.id === moduleTemplate.id ? moduleTemplate : el
            )
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (newEntry.modularCourse && hasIncompleteCard(newEntry)) {
            dispatch(setErrorMessage(`Cannot save. Please complete all module template cards.`));
        } else if (newEntry.modularCourse && duplicateModuleNames(newEntry)) {
            dispatch(
                setErrorMessage(
                    `Cannot save. Module names must be unique within a course template.`
                )
            );
        } else {
            const validation = validateCourseTemplate(newEntry, singleModule);
            setErrors(validation.errors);
            if (!validation.isValid) return;
            setSubmitButtonDisabled(true);
            !newEntry.modularCourse ? createSingleModule() : createMultipleModules();
        }
    };

    const onNavigate = () => {
        clearData();
        navigate('/course_management', { state: { accordionPanel: 'templates' } });
    };

    const onCancel = () => onNavigate();

    // RENDER
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <div className={form.formWrapper}>
            <form className={form.form} onSubmit={onSubmit} data-testid="form_start">
                <h1 className={app.mainHeading}>Edit Course Template</h1>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <h3>Course Name</h3>
                        <p className={pageClasses.courseName}>{newEntry.name}</p>
                        <TextAreaField
                            id={'description'}
                            label={'Course Description'}
                            placeholder={'Enter course description'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            maxLength={CHARACTER_LIMIT}
                            value={newEntry.description || ''}
                            count={`${newEntry.description.length}/${CHARACTER_LIMIT}`}
                            error={errors.description}
                            onChange={onDescriptionChange}
                        />
                        <RadioButtons
                            id="activeTemplateRadio"
                            label="Is Current Template Active?"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.active || false}
                            onChange={onActiveChange}
                        />
                        <div className={pageClasses.contractListSection}>
                            <h3>Available to contracts:</h3>
                            <div className={pageClasses.contractList}>
                                {newEntry.contractIds.map((el) => (
                                    <span className={pageClasses.contract} key={el}>
                                        {contractDetails.find((ele) => ele.id === el)?.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <RadioButtons
                            id="healthInformationRadio"
                            label="Is Health Information Captured?"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.healthInformationCaptured || false}
                            onChange={onHealthInformationCaptured}
                        />
                        <div hidden={!newEntry.healthInformationCaptured}>
                            <MultiSelect
                                id="healthInformationIds"
                                key={keys.healthInformation}
                                label="Health Details"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                placeholder="Health"
                                menuItems={courseTemplateHealthDetails || []}
                                preSelectedIds={newEntry.healthInformationIds}
                                preSelects={courseTemplateHealthDetails.filter((el) =>
                                    newEntry.healthInformationIds.includes(el.id)
                                )}
                                error={errors.healthInformationIds}
                                onChange={(chosenIds) => onHealthChange(chosenIds)}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <h2 className={app.sectionHeading}>Module Details:</h2>
                        <RadioButtons
                            id="multipleModulesRadio"
                            label="Does this Course have Multiple Modules?"
                            disabled={true}
                            value={newEntry.modularCourse || false}
                        />
                        <div hidden={newEntry.modularCourse}>
                            <h3>Module Name</h3>
                            <p className={pageClasses.courseName}>{singleModule.name}</p>
                            <TextAreaField
                                id={'moduleDescription'}
                                label={'Module Description'}
                                placeholder={'Enter module description'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                maxLength={CHARACTER_LIMIT}
                                value={singleModule.description}
                                count={`${singleModule.description.length}/${CHARACTER_LIMIT}`}
                                error={errors.moduleDescription}
                                onChange={onModuleDescriptionChange}
                            />
                            <RadioButtons
                                id="smartTargetRadio"
                                label="Add Smart Target?"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={!!singleModule.smartTarget}
                                onChange={onSmartTargetFlagChange}
                            />
                            <div hidden={!singleModule.smartTarget}>
                                <h2 className={app.sectionHeading}>Smart Target Details:</h2>
                                <TextInputField
                                    id={'action'}
                                    label={'What shall we call this action?'}
                                    placeholder={'Enter the name of the action'}
                                    mandatory={true}
                                    value={singleModule.smartTarget?.name || ''}
                                    error={errors.smartTargetActionName}
                                    onChange={(e) =>
                                        onSmartTargetChange(
                                            'name',
                                            e.target.value,
                                            'smartTargetActionName'
                                        )
                                    }
                                />
                                <SingleSelect
                                    id={'barrierNameId'}
                                    key={keys.smartTargetBarrierName}
                                    label={'Which barrier name aligns to this smart target?'}
                                    mandatory={true}
                                    menuItems={barrierNameDetails || []}
                                    selectedId={singleModule.smartTarget?.barrierNameId || ''}
                                    selected={
                                        barrierNameDetails.find(
                                            (el) =>
                                                el.id === singleModule.smartTarget?.barrierNameId
                                        ) || {}
                                    }
                                    error={errors.smartTargetBarrierName}
                                    onChange={(chosenId) =>
                                        onSmartTargetChange(
                                            'barrierNameId',
                                            chosenId,
                                            'smartTargetBarrierName'
                                        )
                                    }
                                />
                                <SmartDefs />
                                <TextAreaField
                                    id={'smartTargetAction'}
                                    label={'Action'}
                                    placeholder={'Enter action description'}
                                    mandatory={true}
                                    maxLength={ACTION_DESCRIPTION_LIMIT}
                                    value={singleModule.smartTarget?.action || ''}
                                    count={`${singleModule.smartTarget?.action.length}/${ACTION_DESCRIPTION_LIMIT}`}
                                    error={errors.smartTargetActionDescription}
                                    onChange={(e) =>
                                        onSmartTargetChange(
                                            'action',
                                            e.target.value,
                                            'smartTargetActionDescription'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div hidden={!newEntry.modularCourse}>
                            <RadioButtons
                                id="modulesInSetOrder"
                                label="Do the Modules Need to be in a Set Order?"
                                disabled={true}
                                value={newEntry.modulesInSetOrder || false}
                                onChange={onModulesOrderChange}
                            />
                            <h2 className={app.sectionHeading}>Module Templates:</h2>
                            <CardHandler
                                viewerType={MODULE_TEMPLATE_VIEWER}
                                cards={newEntry.modularCourse ? newEntry.moduleTemplates || [] : []}
                                disabled={!hasRole(acceptedRoles, roles) || !newEntry.modularCourse}
                                itemName={'moduleTemplate'}
                                label={'Add a Module'}
                                data={barrierNameDetails || []}
                                sendAdd={addModuleTemplate}
                                sendUpdate={updateModuleTemplate}
                                validate={validateModuleCard}
                            />
                        </div>
                    </div>
                </div>
                <FormActions
                    id="publish"
                    btnText="Update Template"
                    disabled={submitButtonDisabled}
                    onClose={onSubmit}
                    onCancel={onCancel}
                />
            </form>
        </div>
    );
};

export default EditCourseTemplate;
